import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import t from "../translations"

const Root = styled.div`
  height: 18px;
  background-color: #0c9eff1a;
  padding: 2px 8px;
  font-size: 10px;
  font-style: italic;
  color: #0c9eff;
  display: inline-block;
  border-radius: 8px;
  width: fit-content;
`

const ReadingTime = props => {
  const { timeString, locale } = props

  return (
    <Root>
      {t.translate(t.KEYS.READING_TIME, locale)}: {timeString} min.
    </Root>
  )
}

ReadingTime.propTypes = {
  timeString: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}

export default ReadingTime
