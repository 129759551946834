const { localizedPath } = require("./utilities")
const t = require("../translations")

/**
 * Get the URL for the blog page
 * @param {*} pageNumber
 * @param {*} locale
 * @param {*} defaultLocale
 */
const getBlogPagePath = (pageNumber, locale, defaultLocale) => {
  const path = localizedPath(
    pageNumber === 1 ? "" : pageNumber,
    "blog",
    locale,
    defaultLocale
  )
  return path
}

module.exports.getBlogPagePath = getBlogPagePath

/**
 * Get the URL for the article
 * @param {*} article
 * @param {*} locale
 * @param {*} defaultLocale
 */
const getArticlePath = (article, locale, defaultLocale) => {
  const path = localizedPath(
    article.slug,
    t.translate(t.KEYS.ARTICLE_SLUG_PREFIX, locale),
    locale,
    defaultLocale
  )
  return path
}

module.exports.getArticlePath = getArticlePath

/**
 * Get the URL for the Blog Category
 * @param {*} category
 * @param {*} pageNumber
 * @param {*} locale
 * @param {*} defaultLocale
 */
const getBlogCategoryPath = (category, pageNumber, locale, defaultLocale) => {
  if (!category) {
    return null
  }

  let slugWithPage = category.slug

  if (pageNumber && pageNumber > 1) {
    slugWithPage = `${slugWithPage}/${pageNumber}`
  }

  const categoryPath = localizedPath(
    slugWithPage,
    t.translate(t.KEYS.CATEGORY_PAGE_PREFIX, locale),
    locale,
    defaultLocale
  )
  return categoryPath
}

module.exports.getBlogCategoryPath = getBlogCategoryPath
